import React, { useState, useEffect } from "react";
import {View, ScrollView} from "react-native";

import styles from "./style";
import Header from "../../Components/Header";
import Body from "../../Components/Body";

import firebase from "../../Config/firebaseconfig";

export default function Home() {
  return (
    <View style={styles.container}>
      <Header/>
      <Body/>
    </View>
  );
}

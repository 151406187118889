import React, {useState}from "react";
import {View, ImageBackground,} from "react-native";
import styles from "./style";


export default function Header() {

  const [larg, setLarg] = useState(null);

  setInterval(() => {
    var largura = window.screen.width;
    setLarg(largura);
  }, 100);

  return (
    <View style={styles.ContainerHeader}>
      <ImageBackground
        source={larg >= 601 ? require("../../../assets/bg.png") : require("../../../assets/bg_mobile.png")} 
        style={styles.background}
      >
      </ImageBackground>
    </View>
  );
}

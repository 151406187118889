import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  ContainerHeader: {
    height: 558,
    justifyContent: 'center',
  },

  background: {
    flex: 1,
    resizeMode: "cover",
    height: 558,
  },
});

export default styles;

import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import Home from "./src/Pages/Home";


const Stack = createStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: "#054A59",
              shadowColor: "transparent",
            },
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
